<template>
  <v-card>
    <v-card-title class="justify-center mb-2">
      Cadastrar nova Prestação
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="8"
          md="6"
        >
          <v-text-field
            v-model="parcelNumber"
            label="Prazo em meses"
            dense
            outlined
          >
          </v-text-field>

          <v-text-field
            v-model="participantsNumber"
            label="Número de Participantes"
            dense
            outlined
            type="number"
          >
          </v-text-field>
          <v-text-field
            v-model="dueDay"
            label="Dia do Vencimento"
            dense
            outlined
            type="number"
          >
          </v-text-field>
          <v-text-field
            v-model="lifeInsurance"
            label="Seguro Prestamista"
            dense
            outlined
            prefix="%"
            type="number"
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="8"
          md="6"
        >
          <v-text-field
            v-model="contemplatedNumber"
            label="Número de Contemplados"
            dense
            outlined
            type="number"
          >
          </v-text-field>
          <v-text-field
            v-model="admTax"
            label="Taxa de Administração"
            dense
            outlined
            prefix="%"
            type="number"
          >
          </v-text-field>

          <v-text-field
            v-model="backgroundReservation"
            label="Fundo de Reserva"
            dense
            outlined
            prefix="%"
            type="number"
          >
          </v-text-field>
          <v-autocomplete
            v-model="selectedPlans"
            :items="listPlans"
            item-text="name"
            item-value="id"
            label="Planos"
            multiple
            dense
            chips
            outlined
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <div class="d-flex justify-end mt-5">
        <v-btn
          color="info"
          @click="sendModalValue"
        >
          <span
            v-if="!loadingCircleSendForm"
          >Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
        <v-btn
          color="error"
          class="ml-5"
          @click="closeModal"
        >
          Cancelar
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import { mdiCamera } from '@mdi/js'

export default {
  props: {
    updatePage: {
      type: Function,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      parcelNumber: '',
      contemplatedNumber: '',
      participantsNumber: '',
      admTax: '',
      dueDay: '',
      backgroundReservation: '',
      lifeInsurance: '',

      listPlans: [{}],
      selectedPlans: '',
      loadingCircleSendForm: false,

      icons: {
        mdiCamera,
      },

    }
  },

  created() {
    this.getPlans()
  },

  methods: {

    async getPlans() {
      await axiosIns.get('/api/app/v1/plan/index')
        .then(res => {
          this.listPlans = res.data.data
        })
    },

    async sendModalValue() {
      this.loadingCircleSendForm = true

      const body = {
        parcel_number: this.parcelNumber,
        contemplated_number: this.contemplatedNumber,
        participants_number: this.participantsNumber,
        due_day: this.dueDay,
        adm_tax: this.admTax,
        background_reservation: this.backgroundReservation,
        life_insurance: this.lifeInsurance,
        plans_id: this.selectedPlans,
      }

      await axiosIns.post('/api/app/v1/parcel/store', body)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Sua resposta enviada com sucesso!',
            showConfirmButton: false,
            timer: 3000,
          })
          this.updatePage()
          this.loadingCircleSendForm = false
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            showConfirmButton: false,
            text: error,
            timer: 3000,
          })
          this.updatePage()
          this.loadingCircleSendForm = false
        })

      this.closeModal()
    },

    closeModal() {
      this.$emit('close')
    },
  },
}
</script>
<style>
/* Retira o incrementador padrão dos inputs do tipo "number"*/
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
