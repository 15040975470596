<template>
  <v-card>
    <v-card-title class="d-flex justify-content-between">
      <span> Prestações Cadastradas </span>
      <v-spacer></v-spacer>
      <v-btn color="info" @click="openModalPlans">
        <v-icon size="30" class="me-2">
          {{ icons.mdiPlaylistPlus }}
        </v-icon>
        Novo
      </v-btn>
    </v-card-title>
    <v-data-table
      :key="itemsTable.id"
      disable-sort
      :headers="headers"
      :items="itemsTable"
      :loading="loading"
      loading-text="Carregando dados..."
      hide-default-footer
      :page.sync="pageOptions.page"
      :items-per-page="pageOptions.itemsPerPage"
      class="text-no-wrap"
      @page-count="pageCount = $event"
    >
      <template #[`item.parcel_number`]="{ item }"> {{ item.parcel_number }} Meses </template>

      <template #[`item.price`]="{ item }">
        {{ Number(item.price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
      </template>

      <template #[`item.adm_tax`]="{ item }">
        {{ percentageFormat(item.adm_tax) }}
      </template>

      <template #[`item.background_reservation`]="{ item }">
        {{ percentageFormat(item.background_reservation) }}
      </template>

      <template #[`item.life_insurance`]="{ item }">
        {{ percentageFormat(item.life_insurance) }}
      </template>

      <template #[`item.created_at`]="{ item }">
        {{ dateFormat(item.created_at) }}
      </template>

      <template #[`item.action`]="{ item }">
        <v-icon medium class="me-2" @click="openDetails(item)">
          {{ icons.mdiPencilOutline }}
        </v-icon>
      </template>
    </v-data-table>

    <v-card-text class="pt-2 pb-1">
      <v-row>
        <v-col lg="12" cols="12" class="d-flex justify-center">
          <v-pagination
            v-model="pageOptions.page"
            total-visible="6"
            :length="pageOptions.pageCount"
            @input="updatePage"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog v-model="showModalPlans" width="900px" persistent>
      <ModalInstallments :update-page="updatePage" @close="showModalPlans = false"></ModalInstallments>
    </v-dialog>

    <v-dialog v-model="showDetails" width="900px" persistent>
      <InstallmentsDetails
        :key="dataDetails.id"
        :data="dataDetails"
        :update-page="updatePage"
        @close="showDetails = false"
      ></InstallmentsDetails>
    </v-dialog>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'
import { mdiPencilOutline, mdiPlaylistPlus } from '@mdi/js'
import InstallmentsDetails from './InstallmentsDetails.vue'
import ModalInstallments from './ModalInstallments.vue'

export default {
  components: {
    ModalInstallments,
    InstallmentsDetails,
  },
  data() {
    return {
      headers: [
        {
          text: 'PRAZO',
          value: 'parcel_number',
          sortable: false,
          align: 'center',
        },
        {
          text: 'PLANO',
          value: 'plan.name',
          sortable: false,
          align: 'center',
        },
        {
          text: 'CONTEMPLADOS',
          value: 'contemplated_number',
          sortable: false,
          align: 'center',
        },
        {
          text: 'PARTICIPANTES',
          value: 'participants_number',
          sortable: false,
          align: 'center',
        },
        {
          text: 'TAXA DE ADMINISTRAÇÃO',
          value: 'adm_tax',
          sortable: false,
          align: 'center',
        },
        {
          text: 'FUNDO DE RESERVA',
          value: 'background_reservation',
          sortable: false,
          align: 'center',
        },
        {
          text: 'SEGURO DE VIDA PRESTAMISTA',
          value: 'life_insurance',
          sortable: false,
          align: 'center',
        },
        {
          text: 'CADASTRADO',
          value: 'created_at',
          sortable: false,
          align: 'center',
        },
        {
          text: 'EDITAR',
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],
      loading: false,
      loadingCircleInEyeIcon: '',
      showModalPlans: false,
      showDetails: '',
      dataDetails: {},
      percentageFormated: '',

      pageOptions: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },

      icons: {
        mdiPlaylistPlus,
        mdiPencilOutline,
      },
    }
  },

  created() {
    this.getItemsTable()
  },

  methods: {
    async getItemsTable() {
      this.loading = true

      await axiosIns.get('/api/app/v1/parcel/index').then(res => {
        this.itemsTable = res.data.data
        this.loading = false

        this.pageOptions.pageCount = res.data.meta.last_page
      })
    },

    UpdatedTable() {
      this.getItemsTable()
    },

    async updatePage() {
      this.loading = true
      this.itemsTable = []

      await axiosIns.get(`/api/app/v1/parcel/index?page=${this.pageOptions.page}`).then(res => {
        this.itemsTable = res.data.data

        this.pageOptions.pageCount = res.data.meta.last_page
        this.loading = false
      })
    },

    openModalPlans() {
      this.showModalPlans = true
    },

    openDetails(item) {
      this.showDetails = true
      this.dataDetails = item
    },

    dateFormat(date) {
      const dateSliced = date.slice(0, 10)

      const hour = date.slice(11, 19)

      const dateDivided = dateSliced.split('-')

      const dateFormated = `${dateDivided[2]}/${dateDivided[1]}/${dateDivided[0]} - ${hour}`

      return dateFormated
    },

    percentageFormat(percent, digits = 2) {
      return `${parseFloat(percent).toFixed(digits)}%`
    },

    closeModal() {
      this.showModalPlans = false
    },
  },
}
</script>
