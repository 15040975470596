<template>
  <v-skeleton-loader
    v-if="skeleton"
    type="article, actions"
  ></v-skeleton-loader>
  <div v-else>
    <v-card
      :key="dataDetails.id"
    >
      <v-card-title class="justify-center mb-2">
        <span>Descrição</span>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            cols="8"
            md="6"
          >
            <v-text-field
              v-model="dataDetails.parcel_number"
              :append-icon="icons.mdiPencilOutline"
              label="Vencimento"
              dense
              outlined
            >
            </v-text-field>

            <v-text-field
              v-model="dataDetails.participants_number"
              :append-icon="icons.mdiPencilOutline"
              label="Número de Participantes"
              dense
              outlined
              type="number"
            >
            </v-text-field>
            <v-text-field
              v-model="dataDetails.due_day"
              :append-icon="icons.mdiPencilOutline"
              label="Dia do Vencimento"
              dense
              outlined
              type="number"
            >
            </v-text-field>
            <v-text-field
              v-model="dataDetails.life_insurance"
              :append-icon="icons.mdiPencilOutline"
              label="Seguro Prestamista"
              dense
              outlined
              prefix="%"
              type="number"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="8"
            md="6"
          >
            <v-text-field
              v-model="dataDetails.contemplated_number"
              :append-icon="icons.mdiPencilOutline"
              label="Número de Contemplados"
              dense
              outlined
              type="number"
            >
            </v-text-field>
            <v-text-field
              v-model="dataDetails.adm_tax"
              :append-icon="icons.mdiPencilOutline"
              label="Taxa de Administração"
              dense
              outlined
              prefix="%"
              type="number"
            >
            </v-text-field>

            <v-text-field
              v-model="dataDetails.background_reservation"
              :append-icon="icons.mdiPencilOutline"
              label="Fundo de Reserva"
              dense
              outlined
              prefix="%"
              type="number"
            >
            </v-text-field>

            <v-autocomplete
              v-model="dataDetails.plans_id"
              :items="listPlans"
              item-text="name"
              item-value="id"
              label="Planos"
              chips
              dense
              outlined
            >
            </v-autocomplete>
          </v-col>
        </v-row>

        <div class="d-flex justify-end mt-5">
          <v-btn
            color="info"
            @click="sendModalValue"
          >
            <span
              v-if="!loadingCircleSendForm"
            >Enviar</span>
            <v-progress-circular
              v-else
              color="white"
              indeterminate
            >
            </v-progress-circular>
          </v-btn>
          <v-btn
            color="error"
            class="ml-5"
            @click="closeModal"
          >
            Fechar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import { mdiPencilOutline } from '@mdi/js'

export default {
  components: {

  },

  props: {
    data: {
      type: Object,
      required: true,
    },

    updatePage: {
      type: Function,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      skeleton: false,
      loadingCircleSendForm: false,

      parcelNumber: '',
      contemplatedNumber: '',
      participantsNumber: '',
      admTax: '',
      dueDay: '',
      backgroundReservation: '',
      lifeInsurance: '',

      listPlans: [{}],
      selectedPlans: '',

      icons: {
        mdiPencilOutline,
      },
    }
  },

  created() {
    this.getDataById()
    this.getPlans()
  },

  methods: {

    async getPlans() {
      await axiosIns.get('/api/app/v1/plan/index')
        .then(res => {
          this.listPlans = res.data.data
        })
    },

    async getDataById() {
      this.skeleton = true
      await axiosIns.get(`/api/app/v1/parcel/edit/${this.data.id}`).then(
        resp => {
          this.dataDetails = resp.data.data
          this.skeleton = false
        },
      )
    },

    async sendModalValue() {
      this.loadingCircleSendForm = true

      const body = {
        parcel_number: this.dataDetails.parcel_number,
        contemplated_number: this.dataDetails.contemplated_number,
        participants_number: this.dataDetails.participants_number,
        due_day: this.dataDetails.due_day,
        adm_tax: this.dataDetails.adm_tax,
        background_reservation: this.dataDetails.background_reservation,
        life_insurance: this.dataDetails.life_insurance,
        plans_id: this.dataDetails.plans_id,
      }

      await axiosIns.put(`/api/app/v1/parcel/update/${this.data.id}`, body)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Cadastro Alterado!',
            showConfirmButton: false,
            timer: 3000,

          })
          this.loadingCircleSendForm = false
          this.updatePage()
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            showConfirmButton: false,
            text: error,
            timer: 3000,
          })
        })

      this.closeModal()
    },

    closeModal() {
      this.$emit('close')
    },
  },
}
</script>
